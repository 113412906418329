import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { FooterQuery } from "@/types/graphql-types"
import { store } from "@/types/interface"
import Icon from "../common/icon/icon"
import styles from "./footer.module.css"

const footerQuery = graphql`
  query Footer {
    site {
      siteMetadata {
        place
        postalCode
        address
        building
        tel
        receptionTime
        facebook
        instagram
      }
    }
  }
`

type Props = {
  siteInformation: store.SiteInformation["siteInformation"]
}

const Footer: React.FC<Props> = ({ siteInformation }) => {
  const data: NonNullable<FooterQuery> = useStaticQuery(footerQuery)
  const siteMetadata = data.site?.siteMetadata

  return (
    <footer className={styles.footerContainer}>
      <div className={`${styles.iconWrapper} onlyMobile`}>
        <a
          href={siteMetadata?.facebook ? siteMetadata.facebook : "/"}
          target="_blank"
        >
          <Icon icon="facebook" color="var(--color-primary)" size={40} />
        </a>
        <a
          href={siteMetadata?.instagram ? siteMetadata.instagram : "/"}
          target="_blank"
        >
          <Icon icon="instagram" color="var(--color-primary)" size={44} />
        </a>
      </div>
      <h2 className={styles.title}>お問い合わせ先</h2>
      <div className={styles.receptionWrapper}>
        <div className={styles.address}>
          <p>{siteMetadata?.place}</p>
          <p>
            〒{siteMetadata?.postalCode} {siteMetadata?.address}{" "}
            {siteMetadata?.building}
          </p>
        </div>
        <div className={styles.tel}>
          <a href={`tel:${siteInformation?.tel}`}>Tel {siteInformation?.tel}</a>
          <p>{siteInformation?.receptionTime}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
