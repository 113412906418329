import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import logo from '../../../static/logo.png'

const seoQuery = graphql`
  query Seo {
    site {
      siteMetadata {
        title
        description
        url
      }
    }
  }
`

type Props = {
  lang?: string,
  description?: string,
  meta?: any[],
  title?: string
  url?: string
}

const SEO: React.FC<Props> = ({ description, lang = "ja", meta = [], title, url }) => {
  const { site } = useStaticQuery(seoQuery)
  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title
  const metaURL = url || site.siteMetadata.url

  return (
    <Helmet title={metaTitle}>
      <html lang={lang} />
      <meta name="description" content={metaDescription} />
      <meta name="apple-mobile-web-app-title" content={site.siteMetadata.title} />
      <meta name="application-name" content={site.siteMetadata.title} />
      <meta name="viewport" content="width=device-width,initial-scale=1" />
      {/* for IE */}
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
  
      {/* OpenGraph */}
      <meta property="og:url" content={metaURL} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={logo} />
      <meta property="og:locale" content="ja_JP" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={logo} />
    </Helmet>
  )
}

export default SEO
