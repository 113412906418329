import React from "react"
import objectFitImages from "object-fit-images"
import { connect, ConnectedProps } from "react-redux"

import Header from "./header"
import Footer from "./footer"
import { Loading } from "../common"
import { store } from "@/types/interface"
import { getSiteInformation } from "../../actions"
// global styles
import "./layout.css"

const connector = connect(
  // mapStateToProps
  ({ siteInformation }: { siteInformation: store.SiteInformation }) => ({
    siteInformation: siteInformation.siteInformation,
  }),
  // mapDispatchToProps
  { getSiteInformation }
)

type Props = {
  children: React.ReactNode
} & ConnectedProps<typeof connector>

const Layout: React.FC<Props> = ({
  children,
  siteInformation,
  getSiteInformation,
}) => {
  React.useEffect(() => {
    if (!siteInformation) {
      getSiteInformation()
    }
  }, [])

  // enable object-fit in IE
  objectFitImages()
  if (typeof window !== "undefined") {
    // enable smooth scroll
    require("smooth-scroll")('a[href*="#"]')
  }
  return (
    <div className="viewport">
      <Header />
      <main className="siteMain">
        <Loading>{children}</Loading>
      </main>
      <Footer siteInformation={siteInformation} />
    </div>
  )
}

export default connector(Layout)
