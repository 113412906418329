import React from "react"

import Image from "./image"
import { useSwipe } from "../../utils/useGestures"
import styles from "./slider.module.css"

type Props = {
  slideWidth: number
  slideHeight?: number
  photos: string[]
  isStatic?: boolean
  hasSelector?: boolean
  speed?: number
  objectFit?: "cover" | "contain"
}

const Slider: React.FC<Props> = ({
  slideWidth,
  slideHeight,
  photos,
  isStatic = true,
  hasSelector = false,
  speed = 0.5,
  objectFit = "contain",
}) => {
  const totalSlides = photos.length
  const wrapperWidth = totalSlides * slideWidth
  const [pos, setPos] = React.useState(0)

  // automatic slide
  React.useEffect(() => {
    const timerId = setTimeout(() => {
      if (pos < totalSlides - 1) {
        slideRight()
      } else {
        setPos(0)
      }
    }, 5000)
    // invoke when component will unmount (before pos changing)
    return () => {
      clearTimeout(timerId)
    }
  }, [pos])

  const slideLeft = React.useCallback(() => {
    setPos(prev => {
      if (prev > 0) {
        return prev - 1
      } else {
        return prev
      }
    })
  }, [])

  const slideRight = React.useCallback(() => {
    setPos(prev => {
      if (prev < totalSlides - 1) {
        return prev + 1
      } else {
        return prev
      }
    })
  }, [])

  // use gestures
  const { handleTouchDown, handleTouchUp } = useSwipe({
    swipeLeft: slideLeft,
    swipeRight: slideRight,
  })

  return (
    <>
      <ul
        className={styles.slider}
        style={{
          width: `${wrapperWidth}px`,
          left: `${-pos * slideWidth}px`,
          transition: `all ${speed}s cubic-bezier(1, 0.01, 0.32, 1)`,
        }}
        onTouchStart={handleTouchDown}
        onTouchEnd={handleTouchUp}
        onTouchCancel={handleTouchUp}
      >
        {photos.map((photo, i) => {
          return (
            <li
              className={styles.photoFrame}
              key={i}
              style={{
                width: `${slideWidth}px`,
              }}
            >
              {isStatic ? (
                <Image
                  alt="slider photo"
                  filename={photo}
                  width={slideWidth}
                  height={slideHeight}
                />
              ) : (
                <img
                  alt="slider photo"
                  src={photo}
                  width={slideWidth}
                  height={slideHeight}
                  style={{ objectFit: objectFit }}
                />
              )}
            </li>
          )
        })}
      </ul>
      {/* selector */}
      {photos.length !== 1 && hasSelector && (
        <>
          <div onClick={slideRight} className={`${styles.btns} ${styles.next}`}>
            <i className={`${styles.arrow} ${styles.Right}`}></i>
          </div>
          <div
            onClick={slideLeft}
            className={`${styles.btns} ${styles.previous}`}
          >
            <i className={`${styles.arrow} ${styles.Left}`}></i>
          </div>
        </>
      )}
      {/* dot style */}
      {photos.length !== 1 && (
        <ul className={styles.pagenationWrap}>
          {Array(totalSlides)
            .fill(0)
            .map((_, i) => {
              const dot =
                i === pos ? (
                  <li
                    key={i}
                    className={styles.active}
                    style={{
                      transition: `all ${speed}s cubic-bezier(1, 0.01, 0.32, 1)`,
                    }}
                  ></li>
                ) : (
                  <li
                    key={i}
                    style={{
                      transition: `all ${speed}s cubic-bezier(1, 0.01, 0.32, 1)`,
                    }}
                  ></li>
                )
              return dot
            })}
        </ul>
      )}
    </>
  )
}

export default Slider
