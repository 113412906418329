import React from "react"
import { Link, withPrefix, graphql, useStaticQuery } from "gatsby"

import { MenuQuery } from "@/types/graphql-types"
import { MenuIcon, MenuIconProps } from "./menu-icon"
import Icon from '../common/icon/icon'
import Image from "../common/image"
import styles from "./header.module.css"

type Props = MenuIconProps & {
  isHome: boolean
}

// Presentation
const Header: React.FC<Props> = props => {
  return (
    <header>
      <div
        className={`${styles.headerContainer} ${props.isHome &&
          !props.isClicked &&
          styles.home}`}
      >
        <div className={styles.siteMast}>
          <Link to="/">
            <Image
              filename="logo.png"
              alt="site logo"
              className={styles.logo}
            />
          </Link>
          <div className="onlyMobile">
            <MenuIcon isClicked={props.isClicked} onClick={props.onClick} />
          </div>
          <div className="onlyPC">
            <Menu />
          </div>
        </div>
      </div>

      <div
        className={`${styles.menuContainer} ${
          props.isClicked ? null : styles.hidden
        } onlyMobile`}
      >
        <Menu />
        <div className={styles.menuBottom}>
          <Link to="/">TOP</Link>
        </div>
      </div>
    </header>
  )
}

// Menu contents
const menuQuery = graphql`
  query Menu {
    site {
      siteMetadata {
        facebook
        instagram
      }
    }
  }
`

const Menu: React.FC = () => {
  const data: MenuQuery = useStaticQuery(menuQuery)
  const siteMetadata = data.site?.siteMetadata

  return (
    <div className={styles.menu}>
      <Link to="/flyer">最新広告</Link>
      <Link to="/#program">各種講座</Link>
      <Link to="/kids">キッズ</Link>
      <Link to="/excursion">大人の遠足</Link>
      <Link to="/global-business">キッズ・ビジネス</Link>
      <Link to="/#access">アクセス</Link>
      <div className={styles.icons}>
        <a
          href={siteMetadata?.facebook ? siteMetadata.facebook : "/"}
          target="_blank"
        >
          <Icon icon="facebook" color="var(--color-primary)" />
        </a>
        <a
          href={siteMetadata?.instagram ? siteMetadata.instagram : "/"}
          target="_blank"
        >
          <Icon icon="instagram" color="var(--color-primary)" size={26} />
        </a>
      </div>
    </div>
  )
}

// Container
const Container: React.FC = () => {
  const [isHome, setIsHome] = React.useState(false)
  const [isClicked, setIsClicked] = React.useState(false)

  React.useEffect(() => {
    const pathname =
      typeof window !== "undefined" ? window.location.pathname : ""
    setIsHome(pathname === withPrefix("/"))
  }, [])

  const toggleMenu = React.useCallback(() => {
    setIsClicked(!isClicked)
  }, [isClicked])

  return <Header isHome={isHome} isClicked={isClicked} onClick={toggleMenu} />
}

export default Container
