import React from "react"
import { Link } from "gatsby"

import { store } from "@/types/interface"
import { Image } from "../common"
import { useWindowSize } from "../../utils/useWindowSize"
import styles from "./special-lesson-card.module.css"

type Props = {
  lesson: NonNullable<store.ExcursionLesson["excursionLesson"]>
  color: string
}

const SpecialLessonCard: React.FC<Props> = ({ lesson, color }) => {
  return (
    <section className={styles.card}>
      <Link
        to={`/excursion/${lesson._id}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        {isMobile() && <CardHeader lesson={lesson} color={color} />}
        {lesson.imagePaths.length !== 0 ? (
          <img
            alt="course-image"
            src={lesson.imagePaths[0]}
            className={styles.imgWindow}
          />
        ) : (
          <Image
            alt="no photo"
            filename="no-photo.jpg"
            className={styles.imgWindow}
          />
        )}
        {isMobile() && <CardFooter lesson={lesson} />}
        {!isMobile() && (
          <div className={styles.cardSide}>
            <CardHeader lesson={lesson} color={color} />
            <CardFooter lesson={lesson} />
          </div>
        )}
      </Link>
    </section>
  )
}

const CardHeader: React.FC<Props> = ({ lesson, color }) => (
  <header className={styles.headerContainer}>
    <div className={styles.date}>{getDate(lesson.lessonDay)}</div>
    <div className={styles.day} style={{ background: color }}>
      {getDayOfWeek(lesson.lessonDay)}
    </div>
    <div className={styles.time}>{lesson.time}</div>
  </header>
)

const CardFooter: React.FC<Pick<Props, "lesson">> = ({ lesson }) => (
  <footer>
    <h2 className={styles.cardTitle}>{lesson.title}</h2>
    {lesson.teacherName && (
      <p className={styles.teacher}>
        {lesson.teacherTitle && `${lesson.teacherTitle}\n`}
        {lesson.teacherName && `${lesson.teacherName}`}
      </p>
    )}
  </footer>
)

const isMobile = () => {
  const windowSize = useWindowSize()
  return windowSize.width ? windowSize.width < 768 : undefined
}

const getDayOfWeek = (strDate: string): string => {
  const date = new Date(strDate)
  return ["日", "月", "火", "水", "木", "金", "土"][date.getDay()]
}

const getDate = (strDate: string): string => {
  const date = new Date(strDate)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  return year + "/" + month + "/" + day
}

export default SpecialLessonCard
