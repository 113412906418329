import React from 'react'
import svgLib from './svg'

const defaultStyles = { display: 'inline-block', verticalAlign: 'middle' }

type Props = {
  icon: string
  size?: number
  color?: string
  className?: string
  style?: React.CSSProperties
}

export const Icon: React.FC<Props> = (props) => {
  const { style, icon, size = 24, color = '#686868', ...rest } = props
  const styles = { ...style, ...defaultStyles, fill: color }

  const SvgIcon = svgLib[icon]

  return <SvgIcon width={`${size}px`} height={`${size}px`} style={styles} {...rest} />
}

export default Icon
