import React from "react"
import { Link } from "gatsby"

import { store } from "@/types/interface"
import { Image } from "../common"
import { useWindowSize } from "../../utils/useWindowSize"
import styles from "./course-card.module.css"

type Props = {
  course: store.Course["courseAbstracts"][0]
  color?: string
}

const CourseCard: React.FC<Props> = ({ course, color }) => {
  return (
    <section className={styles.card}>
      <Link
        to={`/course/${course._id}`}
        style={{ textDecoration: "none", color: "black" }}
      >
        {isMobile() && <CardHeader course={course} color={color} />}
        {course.imagePaths.length !== 0 ? (
          <img
            alt="course-image"
            src={course.imagePaths[0]}
            className={styles.imgWindow}
          />
        ) : (
          <Image alt="no photo" filename="no-photo.jpg" className={styles.imgWindow} />
        )}
        {isMobile() && <CardFooter course={course} />}
        {!isMobile() && (
          <div className={styles.cardSide}>
            <CardHeader course={course} color={color} />
            <CardFooter course={course} />
          </div>
        )}
      </Link>
    </section>
  )
}

const CardHeader: React.FC<Props> = ({ course, color }) => (
  <header className={styles.headerContainer}>
    <div className={styles.dateWrapper}>
      {course.dateDisplays.map((date, i) => {
        return (
          <p style={color ? { backgroundColor: color } : undefined} key={i}>
            {date}
          </p>
        )
      })}
    </div>
    <div className={styles.timeWrapper}>
      {course.times.map((time, i) => {
        return <p key={i}>{time}</p>
      })}
    </div>
  </header>
)

const CardFooter: React.FC<Pick<Props, "course">> = ({ course }) => (
  <footer>
    <h2 className={styles.cardTitle}>{course.title}</h2>
    <p className={styles.teacher}>
      {course.teacherTitle && `${course.teacherTitle}\n`}
      {course.teacherName}
    </p>
  </footer>
)

const isMobile = () => {
  const windowSize = useWindowSize()
  return windowSize.width ? windowSize.width < 768 : undefined
}

export default CourseCard
