import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { AllImagesQuery } from "@/types/graphql-types"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

type Props = {
  filename: string
  alt?: string
  className?: string
  bg?: boolean
  width?: number
  height?: number
  children?: JSX.Element | JSX.Element[]
}

const imageQuery = graphql`
  query AllImages {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 1120, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const Image: React.FC<Props> = props => {
  const data: AllImagesQuery = useStaticQuery(imageQuery)

  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes(props.filename)
  })

  if (!image) {
    return null
  }

  const imageFluid = image.node.childImageSharp?.fluid

  if (props.bg) {
    return (
      <BackgroundImage
        fluid={imageFluid as FluidObject}
        className={props.className}
      >
        {props.children}
      </BackgroundImage>
    )
  } else {
    return (
      <Img
        fluid={imageFluid as FluidObject}
        alt={props.alt}
        className={props.className}
        style={{ width: props.width, height: props.height}}
      />
    )
  }
}

export default Image
