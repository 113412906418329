import React from "react"

import styles from "./menu-icon.module.css"

export type MenuIconProps = {
  isClicked: boolean
  onClick: () => void
}

export const MenuIcon: React.FC<MenuIconProps> = ({ isClicked, onClick }) => {
  const changeClass = isClicked ? styles.change : null

  return (
    <div className={styles.menuIcon} onClick={onClick}>
      <div className={`${styles.bar1} ${changeClass}`}></div>
      <div className={`${styles.bar2} ${changeClass}`}></div>
      <div className={`${styles.bar3} ${changeClass}`}></div>
    </div>
  )
}
