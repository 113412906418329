import React from "react"
import { connect, ConnectedProps } from "react-redux"

import styles from "./loading.module.css"

const connector = connect(
  // mapStateToProps
  ({ loading }: { loading: any }) => ({
    loading: loading,
  })
)

type Props = {
  children: React.ReactNode
} & ConnectedProps<typeof connector>

const Loading: React.FC<Props> = ({ children, loading }) => {
  const [isLoading, setIsLoading] = React.useState(true)
  React.useEffect(() => {
    const timerId = setTimeout(() => {
      setIsLoading(isNowLoading(loading))
    }, 200)
    return () => {
      clearTimeout(timerId)
    }
  }, [loading])

  return (
    <>
      <div className={`${styles.loadingScreen} ${!isLoading && styles.inActive}`}>
        <div className={styles.loader}>Loading...</div>
      </div>
      {children}
    </>
  )
}

const isNowLoading = (loading: { [actionType: string]: boolean }) => {
  // check all action has finished or not
  const loadingFlag = Object.entries(loading).find(e => e[1] === true)
  // if all actions are not loading state, return false
  if (!loadingFlag) {
    return false
  }
  return true
}

export default connector(Loading)
