import { useState, useCallback, TouchEvent } from "react"

// ______________________________________________________
//
// @ Types

type State = {
  // offsetX: number
  startX: number
}
type Options = {
  swipeRight: () => void
  swipeLeft: () => void
}
type Props = Partial<Options>
// ______________________________________________________
//
// @ Defaults

const defaultState: State = {
  // offsetX: 0,
  startX: 0,
}
// ______________________________________________________
//
// @ Hooks

function useSwipe(props?: Props) {
  const [state, update] = useState<State>(defaultState)

  const handleTouchDown = useCallback((event: TouchEvent<HTMLElement>) => {
    event.persist()
    update(_state => {
      const startX = event.touches[0].clientX
      return {
        ..._state,
        startX,
      }
    })
  }, [])
  const handleTouchUp = useCallback((event: TouchEvent<HTMLElement>) => {
    event.persist()
    update(_state => {
      const offsetX = event.changedTouches[0].clientX - _state.startX
      if (offsetX > 80) props?.swipeLeft && props.swipeLeft()
      if (offsetX < -80) props?.swipeRight && props.swipeRight()
      return {
        ..._state,
        startX: 0,
      }
    })
  }, [])
  // const handleTouchMove = useCallback((event: TouchEvent<HTMLElement>) => {
  //   event.persist()
  //   update(_state => {
  //     const offsetX = event.touches[0].clientX - _state.startX
  //     return {
  //       ..._state,
  //       offsetX,
  //     }
  //   })
  // }, [])

  return {
    handleTouchDown,
    handleTouchUp,
    // handleTouchMove,
  }
}
// ______________________________________________________
//
// @ exports

export { useSwipe }
