import React from "react"
import CSS from "csstype"

import styles from "./accordion.module.css"

type Props = {
  children: JSX.Element | JSX.Element[]
  label: string
}

const Accordion: React.FC<Props> = ({ children, label }) => {
  const [isOpen, setIsOpen] = React.useState(true)
  const [openStyle, setOpenStyle] = React.useState<CSS.Properties>({
    height: "auto",
    opacity: 1,
  })
  const targetRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (targetRef.current) {
      setOpenStyle({
        height: `${targetRef.current.offsetHeight}px`,
        opacity: 1,
      })
    }
  }, [])

  return (
    <>
      <div className={styles.acdLabel} onClick={() => setIsOpen(!isOpen)}>
        <h2>{label}</h2>
        <div className={`${styles.arrow} ${isOpen ? styles.Up : styles.Down}`} ></div>
      </div>
      <div
        className={styles.acdContent}
        style={isOpen ? openStyle : undefined}
        ref={targetRef}
      >
        {children}
      </div>
    </>
  )
}

export default Accordion
