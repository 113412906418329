import React from "react"

const Facebook: React.FC = props => {
  return (
    <svg {...props} viewBox="0 0 125 125" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.8242 0C10.1806 0 0 10.1806 0 22.8242V102.176C0 114.819 10.1806 125 22.8242 125H65.8321V76.1329H52.9103V58.5391H65.8321V43.5079C65.8321 31.6985 73.467 20.8555 91.0547 20.8555C98.1757 20.8555 103.441 21.5391 103.441 21.5391L103.027 37.9689C103.027 37.9689 97.657 37.9181 91.7969 37.9181C85.4545 37.9181 84.4375 40.8404 84.4375 45.6916V58.5392H103.531L102.699 76.133H84.4375V125H102.176C114.819 125 125 114.82 125 102.176V22.8244C125 10.1807 114.819 0.000125 102.176 0.000125H22.8241L22.8242 0Z" />
    </svg>
  )
}

export default Facebook
